@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'AmsiProCond-Black';
  src: url('../public/fonts/AmsiProCond-Black.ttf');
}

@font-face {
  font-family: 'Halimun';
  src: url('../public/fonts/Halimun.ttf');
}

@font-face {
  font-family: 'scriptin';
  src: url('../public/fonts/SCRIPTIN.ttf');
}

@font-face {
  font-family: 'Bellisa_Script';
  src: url('../public/fonts/bellisa-script.ttf');
}

@font-face {
  font-family: 'Gloriant_Signature';
  src: url('../public/fonts/Gloriant-RxKW.ttf');
}

@font-face {
  font-family: 'Louis_George_Cafe';
  src: url('../public/fonts/louis-george-cafe.bold-italic.ttf');
}

@layer base {
  body,
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: var(--font-family);
  }
/* global.css */
.slider-primary {
  @apply text-primary; /* This assumes 'text-primary' is the Tailwind class for your primary color */
}

  h1, .heading-secondary {
    font-family: var(--font-family), sans-serif;
  }

  h2,h3 .heading-alternative {
    font-family: var(--secondary-font-family), sans-serif;
  }

   h4, h5, h6, .heading-default {
    font-family: var(--alternative-font-family), sans-serif;
  }
  p{
    font-family:var(--secondary-font-family), sans-serif;
  }
  /* If you have specific elements like blockquotes or callouts where you want to use the alternative font */
  blockquote, .callout {
    font-family: var(--alternative-font-family), sans-serif;
  }
  span{
    font-family:var(--font-family), sans-serif;
  }
  a{
    font-family:var(--secondary-font-family), sans-serif;
  }
  button{
    font-family:var(--secondary-font-family), sans-serif;
  }
  /* Other styles here */
}
.shimmer {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1s infinite;
}
.pagination {
  @apply flex justify-center mt-8;
}
/* Scrollbar styles */
/* Add thin scrollbar for Chrome, Edge, and Safari. */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 20px;
  border: transparent;
}

/* Add thin scrollbar for Firefox */
/* * {
  scrollbar-width: thin;
  scrollbar-color: rgba(156, 163, 175, 0.5) transparent;
} */
.pagination a {
  @apply mx-1 px-3 py-2 bg-white rounded text-sm border border-gray-300 text-gray-500 hover:bg-primary hover:text-white;
}
.custom-select::-moz-focus-inner {
  border: 0;
}
.custom-select:focus {
  outline: none;
}
.pagination .active a {
  @apply bg-primary border-transparent text-white;
}

.pagination .break-me a {
  cursor: not-allowed; /* Show a not-allowed cursor */
  color: #a0a0a0; /* Change the text color to indicate it is disabled */
  pointer-events: none; /* Disable pointer events to prevent clicking */
}
.animated-icon {
  transition: opacity 0.5s ease-in-out;
}


/* Now target the react-input-range classes and override the necessary properties */
.input-range__track--active,
.input-range__slider {
  background: var(--primary-color) !important;
  border: 1px solid  var(--primary-color) !important;
}

.input-range__label--value,
.input-range__label--min,
.input-range__label--max,.input-range__label,.input-range__slider:focus {
  color: var(--primary-color) !important;
}
.input-range__label-container {
  display: none;
}



@layer components {
  .size-selector input:checked + label {
    background-color: var(--primary-color);
    color: white;
  }
  .color-selector input:checked + label {
    border: 2px solid var(--primary-color);
  }
  .input-box {
  width: 100%;
  display: block;
  border: 1px solid var(--primary-color);
  padding: 0.5rem; /* Tailwind's default spacing scale */
  background-color: white;
  color: #4b5563; /* Tailwind gray-700 */
  font-size: 0.875rem; /* Close to Tailwind text-sm */
  border-radius: 0.25rem; /* Tailwind rounded-md */
  transition: border-color 0.3s ease;
}

.input-box:focus {
  outline: none;
  border-color: var(--primary-color); /* Tailwind blue-400 */
  box-shadow: 0 0 0 1px var(--primary-color); /* Tailwind ring-1 and ring-blue-400 */
}

}

@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.heart-pulse:hover {
  animation: pulse 0.5s infinite;
}

@keyframes heart-beat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Mobile Responsive: Up to 640px */
@media (max-width: 640px) {
  .carousel .slide iframe {
    width: -webkit-fill-available !important; 
    margin:0 !important;
  
  }
}
.animate-heart-beat {
  animation: heart-beat 0.5s infinite;
}
@keyframes shimmer {
  0% { background-position: -150% 0; }
  100% { background-position: 150% 0; }
}
.animate-shimmer {
  background-size: 200% 200%;
  animation: shimmer 2s infinite;
}

@keyframes slide {
  0% { transform: translateY(100%); }
  25% { transform: translateY(0); }
  50% { transform: translateY(-100%); }
  75% { transform: translateY(100%); }
  100% { transform: translateY(0); }
}

.animate-slide {
  animation: slide 5s linear infinite;
}
/* styles.css */
/* Custom Scrollbar */
.scrollbar-thin::-webkit-scrollbar {
  width: 8px;
}
.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: var(--primary-color); /* Modify this to change the thumb color */
  border-radius: 6px;
  border: 2px solid transparent;
  background-clip: content-box;
}
.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-color); /* Modify this to change the hover thumb color */
}
.scrollbar-thin::-webkit-scrollbar-track {
  background-color: #f0f4f8; /* Modify this to change t he track color */
  border-radius: 8px;
}
.gem {
  width: 50px;
  height: 75px;
  background: linear-gradient(45deg, #5ee7df, #b490ca);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  position: relative;
  animation: sparkle 1.5s infinite;
}

.gem:before {
  content: "";
  width: 100%;
  height: 50%;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 15%;
  left: 0;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  transform: rotate(-45deg);
}

@keyframes sparkle {
  0% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-5px) scale(1.05);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}
.jewel-loading {
  width: 80px;
  height: 120px;
  background: linear-gradient(90deg, #f0f0f0, #f9f9f9, #f0f0f0);
  animation: shimmer 1.5s infinite;
  background-size: 200% 100%;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  margin: auto;
}
/* Add this to your global CSS file */
.prose img[data-align="right"] {
    float: right; /* Align images to the right */
    margin-left: 80px; /* Add some space between the text and the image */
}
@media (max-width: 1024px) { /* This is the typical breakpoint for 'lg' in Tailwind */
  .lg-two-columns {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important; /* Force 2 columns on lg screens */
  }
}

.custom-swal {
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Box shadow */
}
.currency-symbol{
  font-family:"arial"!important;
  font-size:0.8em !important;
  margin-right:2px !important ; 

}
.custom-swal-title {
  font-weight: bold;
}

.custom-swal-content {
  font-size: 16px;
}
.swal-title {
  font-size: 20px;
  color: #333;
}

.swal-text {
  font-size: 16px;
  color: #555;
}
.custom-confirm-button {
  background-color: #3085d6; /* Confirm button background color */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.custom-confirm-button:hover {
  background-color: #2874a6; /* Darker shade for hover */
}

.custom-cancel-button {
  background-color: #d33; /* Cancel button background color */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  margin-left: 5px;
}

.custom-cancel-button:hover {
  background-color: #b82e2e; /* Darker shade for hover */
}

.sun-editor-editable .se-image-container a img {
  padding: 1px !important;
  margin: 1px !important;
  outline: 0px solid #4592ff !important;

}
.my-custom-popup {
  background-color: #f7f9fc !important;
  border-radius: 10px !important;
  font-family: 'Arial', sans-serif !important;
  padding: 20px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.my-custom-popup .swal2-title {
  color: #1d72b8 !important;
  font-size: 24px !important;
  font-weight: bold !important;
}

.my-custom-popup .swal2-icon {
  margin-top: 10px !important;
  color: #1d72b8 !important;
}

.my-custom-popup .swal2-icon.swal2-success [class^='swal2-success-line'] {
  background-color: #1d72b8 !important;
}

.my-custom-popup .swal2-content {
  color: #333 !important;
  font-size: 18px !important;
  margin-top: 10px !important;
}

.my-custom-popup .swal2-confirm {
  background-color: #1d72b8 !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  font-size: 16px !important;
  padding: 10px 20px !important;
}

.my-custom-popup .swal2-confirm:hover {
  background-color: #155a8a !important;
}

.my-custom-popup .swal2-timer-progress-bar {
  background: #1d72b8 !important;
}
@media (max-width: 460px) {
  .engt .engt-launch-icon-box {
      bottom: 60px !important;
      position: absolute;
      left: -24px !important;
  }
}

@media (max-width: 768px) {
  .engt .engt-launch-icon-box {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    bottom: 30px;
    left: 12px;
  }
}


/* customScrollbar.css */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color)  #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar {
  height: 12px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--primary-color) !important;;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

/* Snowflake animation */
/* Snowflake container to avoid layout issues */
.snowflake-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none; /* Ensure it doesn't block interactions */
  z-index: 9999; /* Above all other content */
}

.snowflake {
  position: absolute;
  top: 0;
  color: #D2ECEB;
  opacity: 0.8;
  font-size: 1rem;
  animation: fall linear infinite;
}

@keyframes fall {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100vh);
  }
}
